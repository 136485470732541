const tfOuput = require('./tf_output.json');

// TODO apiUrl: route that to domain via dns, so getting it manually form sls info won't be needed anymore
const apiUrl = 'https://eshifhd829.execute-api.ap-northeast-1.amazonaws.com';
const browserProxyAddress = `http://${tfOuput.web_proxy_private_ip.value}:8118`;
const dbCluster = tfOuput.documentdb_endpoint.value;
const bucketName = 'aph-prod-puppeteer-screenshots';
const merchantsBucketName = 'aph-prod-backgrounds';
const fxmarketURL = 'https://fxmarketapi.com';
const fxMarketApiKey = '6zEqW9BHCr0gp9ugHO16';
const ppjProcessingURL = 'merchant4.acm-processing.com';
const ppjProcessingKey = '0810055182e23220bc5fa1d1e7e913b2f577bd4c';
const dbUsername = 'aphadmin';
const dbName = 'aphdocdb';
// const dbHost = "db.widget.btb-paytech.com"; // subdomain for widget domain
const dbHost = dbCluster;
const region = 'ap-northeast-1';
const aphEnv = 'prod';
const testMerchant = '61bb3dce62dcb8bcada9afe0';
const transactionLimitPerDay = 8;
const minTransferAmount = 1000;
const maxTransferAmount = 2.15 * 1000 * 1000;
const unsettledPendingsLimit = 2;
const ftdUnsettledPendingsLimit = 1;
const minSuccessAmount = 40_000;
const nftdFlowStartDate = '2024-03-25';
const ftdTransferLimitAmount = 10_000;
const adminTimezone = { timeZone: 'europe/warsaw' };
const banksLogosUrl =
  'https://aph-prod-banks-logos.s3.ap-northeast-1.amazonaws.com';
const snsApiAlertsArn = tfOuput.sns_api_alert_arn.value;
const adminApiUrl =
  'https://2hgiv4l56m.execute-api.ap-northeast-1.amazonaws.com/';
const semiRecipientAccountsUrl = 'https://api.merchant.safe-paytech.com';
const chromiumLayer =
  'arn:aws:lambda:ap-northeast-1:274733071112:layer:chromium-layer:1';

const lambdaRoleArn = tfOuput.aph_lambda_role.value;
const elbName = tfOuput.web_proxy_elb_name.value;
const defaultDailyLimits = {
  total: 20,
  failed: 10,
};

const widget = {
  domain: 'widget.btb-paytech.com',
  url: 'https://widget.btb-paytech.com',
  hostedZoneId: 'Z036238818ZHNGWPBH0B0',
  certArn:
    'arn:aws:acm:us-east-1:274733071112:certificate/846a1621-46bd-4a09-8470-8ed68886b337',
};

const widgetPlayground = {
  //TMP: not in use
  domain: '',
  url: '',
  hostedZoneId: '',
  certArn: '',
};

const admin = {
  domain: 'portal-paytech.com',
  url: 'https://portal-paytech.com',
  hostedZoneId: 'Z014819014EXJ9W71D767',
  certArn: '',
};

const customAdmin = {
  domain: 'bo.portal-paytech.com',
  url: 'https://bo.portal-paytech.com',
  hostedZoneId: 'Z0555564YXQJT9M2BKO7',
  certArn:
    'arn:aws:acm:us-east-1:274733071112:certificate/4f0ae07a-b26b-48ba-851b-f3e512f40c67',
};

const proxyEc2 = [
  {
    id: tfOuput.web_proxy_id.value,
    address: `http://${tfOuput.web_proxy_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_0', // original name aph_prod_web_proxy_ec2, changed for Grafana analytics
    dedicatedFor: [],
    vpnConfig: 'jp660.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_id.value,
    address: `http://${tfOuput.web_proxy_backup_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_1', // original name aph_prod_web_proxy_ec2_backup, changed for Grafana analytics
    dedicatedFor: [],
    vpnConfig: 'jp661.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_2_id.value,
    address: `http://${tfOuput.web_proxy_backup_2_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_2',
    dedicatedFor: [],
    vpnConfig: 'jp667.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_3_id.value,
    address: `http://${tfOuput.web_proxy_backup_3_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_3',
    dedicatedFor: [],
    vpnConfig: 'jp652.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_4_id.value,
    address: `http://${tfOuput.web_proxy_backup_4_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_4',
    dedicatedFor: [],
    vpnConfig: 'jp672.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_5_id.value,
    address: `http://${tfOuput.web_proxy_backup_5_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_5',
    dedicatedFor: [],
    vpnConfig: 'jp651.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_6_id.value,
    address: `http://${tfOuput.web_proxy_backup_6_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_6',
    dedicatedFor: [],
    vpnConfig: 'japan_yokohama_udp',
    vpnAccessId: '18477996',
    vpnType: 'ExpressVPN',
  },
  {
    id: tfOuput.web_proxy_backup_7_id.value,
    address: `http://${tfOuput.web_proxy_backup_7_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_7',
    dedicatedFor: [],
    vpnConfig: 'jp-tok.prod.surfshark.com_tcp',
    vpnAccessId: 'Surfshark_1',
    vpnType: 'Surfshark',
  },
  {
    id: tfOuput.web_proxy_backup_8_id.value,
    address: `http://${tfOuput.web_proxy_backup_8_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_8',
    dedicatedFor: [],
    vpnConfig: 'jp710.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_9_id.value,
    address: `http://${tfOuput.web_proxy_backup_9_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_9',
    dedicatedFor: [],
    vpnConfig: 'jp-tok.prod.surfshark.com_tcp',
    vpnAccessId: 'Surfshark_1',
    vpnType: 'Surfshark',
  },
  {
    id: tfOuput.web_proxy_backup_10_id.value,
    address: `http://${tfOuput.web_proxy_backup_10_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_10',
    dedicatedFor: [],
    vpnConfig: 'jp697.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_11_id.value, // banned for paypay with japan_tokyo_udp_1 config
    address: `http://${tfOuput.web_proxy_backup_11_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_11',
    dedicatedFor: [],
    vpnConfig: 'jp-tok.prod.surfshark.com_tcp',
    vpnAccessId: 'Surfshark_1',
    vpnType: 'Surfshark',
  },
  {
    id: tfOuput.web_proxy_backup_12_id.value, //banned for paypay with shibuya config
    address: `http://${tfOuput.web_proxy_backup_12_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_12',
    dedicatedFor: [],
    vpnConfig: 'jp620.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_13_id.value,
    address: `http://${tfOuput.web_proxy_backup_13_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_13',
    dedicatedFor: [],
    vpnConfig: 'jp650.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_14_id.value,
    address: `http://${tfOuput.web_proxy_backup_14_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_14',
    dedicatedFor: [],
    vpnConfig: 'jp-tok.prod.surfshark.com_tcp',
    vpnAccessId: 'Surfshark_1',
    vpnType: 'Surfshark',
  },
  {
    id: tfOuput.web_proxy_backup_15_id.value,
    address: `http://${tfOuput.web_proxy_backup_15_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_15',
    dedicatedFor: [],
    vpnConfig: 'jp-tok.prod.surfshark.com_tcp',
    vpnAccessId: 'Surfshark_1',
    vpnType: 'Surfshark',
  },
  {
    id: tfOuput.web_proxy_backup_16_id.value,
    address: `http://${tfOuput.web_proxy_backup_16_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_16',
    dedicatedFor: [],
    vpnConfig: 'jp-free-406003.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_1',
    vpnType: 'ProtonVPN',
  },
  {
    id: tfOuput.web_proxy_backup_17_id.value,
    address: `http://${tfOuput.web_proxy_backup_17_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_17',
    dedicatedFor: [],
    vpnConfig: 'jp-tok.prod.surfshark.com_tcp',
    vpnAccessId: 'Surfshark_1',
    vpnType: 'Surfshark',
  },
  {
    id: tfOuput.web_proxy_backup_18_id.value,
    address: `http://${tfOuput.web_proxy_backup_18_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_18',
    dedicatedFor: [],
    vpnConfig: 'jp694.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_19_id.value,
    address: `http://${tfOuput.web_proxy_backup_19_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_19',
    dedicatedFor: [],
    vpnConfig: 'jp696.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_20_id.value,
    address: `http://${tfOuput.web_proxy_backup_20_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_20',
    dedicatedFor: [],
    vpnConfig: 'jp695.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_21_id.value,
    address: `http://${tfOuput.web_proxy_backup_21_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_21',
    dedicatedFor: [],
    vpnConfig: 'jp698.nordvpn.com.tcp',
    vpnAccessId: 'Nord_VPN_2',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_22_id.value,
    address: `http://${tfOuput.web_proxy_backup_22_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_22',
    dedicatedFor: [],
    vpnConfig: 'jp-43.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_2',
    vpnType: 'ProtonVPN',
  },
  {
    id: tfOuput.web_proxy_backup_23_id.value,
    address: `http://${tfOuput.web_proxy_backup_23_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_23',
    dedicatedFor: [],
    vpnConfig: 'jp-44.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_2',
    vpnType: 'ProtonVPN',
  },
  {
    id: tfOuput.web_proxy_backup_24_id.value,
    address: `http://${tfOuput.web_proxy_backup_24_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_24',
    dedicatedFor: [],
    vpnConfig: 'jp-45.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_2',
    vpnType: 'ProtonVPN',
  },
  {
    id: tfOuput.web_proxy_backup_25_id.value,
    address: `http://${tfOuput.web_proxy_backup_25_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_25',
    dedicatedFor: [],
    vpnConfig: 'jp-46.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_2',
    vpnType: 'ProtonVPN',
  },
  {
    id: tfOuput.web_proxy_backup_26_id.value,
    address: `http://${tfOuput.web_proxy_backup_26_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_26',
    dedicatedFor: [],
    vpnConfig: 'jp-47.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_2',
    vpnType: 'ProtonVPN',
  },
  {
    id: tfOuput.web_proxy_backup_27_id.value,
    address: `http://${tfOuput.web_proxy_backup_27_private_ip.value}:8118`,
    name: 'aph_prod_web_proxy_ec2_backup_27',
    dedicatedFor: [],
    vpnConfig: 'jp-48.protonvpn.tcp',
    vpnAccessId: 'Protonvpn_2',
    vpnType: 'ProtonVPN',
  },
];

const csvService = {
  name: 'aph-serverless-prod-csvService',
  s3: 'aph-prod-csv-bucket',
};

const accountsStatus = {
  domain: 'status.portal-paytech.com',
  url: 'https://status.portal-paytech.com',
  hostedZoneId: 'Z02002363SNQIAMV6VFU7',
  certArn:
    'arn:aws:acm:us-east-1:274733071112:certificate/89db3b1c-6fa1-4f76-ae34-cbbe5529241b',
};

module.exports = {
  apiUrl,
  browserProxyAddress,
  dbCluster,
  bucketName,
  merchantsBucketName,
  fxmarketURL,
  fxMarketApiKey,
  ppjProcessingURL,
  ppjProcessingKey,
  dbUsername,
  dbHost,
  dbName,
  widget,
  widgetPlayground,
  admin,
  region,
  lambdaRoleArn,
  aphEnv,
  testMerchant,
  transactionLimitPerDay,
  minTransferAmount,
  maxTransferAmount,
  adminTimezone,
  banksLogosUrl,
  snsApiAlertsArn,
  customAdmin,
  adminApiUrl,
  elbName,
  proxyEc2,
  defaultDailyLimits,
  csvService,
  accountsStatus,
  semiRecipientAccountsUrl,
  chromiumLayer,
  unsettledPendingsLimit,
  ftdUnsettledPendingsLimit,
  minSuccessAmount,
  nftdFlowStartDate,
  ftdTransferLimitAmount,
};
